/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:49:35
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-07 11:48:18
 */
import ChartDataTool from '@/service/chartDataTool'
import ChartContainer from '../modules/chartContainer'
import PieChart from '../modules/pieChart'

export default {
  name: 'Marriage',
  components: {
    ChartContainer,
    PieChart
  },
  data () {
    return {
      colors: [
        { color: '#47BDFF', endColor: '#2C68FF' },
        { color: '#58F6F9', endColor: '#14C6CA' },
        { color: '#EF50C1', endColor: '#F38AFF' },
        { color: '#8ED6FF', endColor: '#BFD1FF' },
        { color: '#E6E6E6', endColor: '#E6E6E6' }
      ]
    }
  },
  props: {
    data: {
      type: Array,
      default: () => ([
        // { value: 28.67, name: '0-18岁：28.67%', color: '#47BDFF', endColor: '#2C68FF' },
        // { value: 18.6, name: '19-25岁：18.60%', color: '#58F6F9', endColor: '#14C6CA' },
        // { value: 18.33, name: '26-35岁：18.33%', color: '#EF50C1', endColor: '#F38AFF' },
        // { value: 17, name: '36-45岁：17.00%', color: '#8ED6FF', endColor: '#BFD1FF' },
        // { value: 7, name: '45岁以上：7.00%', color: '#E6E6E6', endColor: '#E6E6E6' }
      ])
    }
  },
  computed: {
    chartData () {
      const self = this
      if (!Array.isArray(self.data) || self.data.length === 0) {
        return []
      }
      const tool = new ChartDataTool()
      return this.data.reduce((res, { label_name: name, user_count: count, total }, index) => {
        const rate = tool.getRate(count, total)
        res = res.concat({
          name: name + ': ' + rate.toFixed(2) + '%',
          value: rate,
          ...self.colors[index]
        })
        return res
      }, [])
    }
  }
}
