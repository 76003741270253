import { render, staticRenderFns } from "./marriage.vue?vue&type=template&id=47d0c654&scoped=true&"
import script from "./marriage.js?vue&type=script&lang=js&"
export * from "./marriage.js?vue&type=script&lang=js&"
import style0 from "./marriage.scss?vue&type=style&index=0&id=47d0c654&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d0c654",
  null
  
)

export default component.exports